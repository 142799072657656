import emailjs from 'emailjs-com';
const config = require("../../config.json")

const sendMail = (params, template) => {
    return new Promise((resolve, reject) => {
        emailjs.send(config.EMAILJS_SERVICE_ID, template ? template : config.EMAILJS_DEFAULT_TEMPLATE_ID, params, config.EMAILJS_USER_ID)
            .then((result) => {
                resolve(result)
            }, (error) => {
                reject(error)
            });
    })
}

export default sendMail